<template>
    <div class="content-main">
        <div class="desc">
            <h1>公司介绍</h1>
            <h2>COMPANY INTRODUCTION</h2>
            <p>我们是一家成立于2017年，年轻的以创意为主导、</p>
            <p>技术为驱动的广告创意公司，隶属于源来创意集团。</p>
            <p>我们更专注于对消费者洞察的精准把握，</p>
            <p>并擅长把洞察转化为鲜明的观点和付诸于优秀的创意内容及互动形式。</p>
            <p>我们的终极目标并不是为品牌打造杰出的广告作品，</p>
            <p>而是通过内容和技术实现品牌与用户之间的对话，</p>
            <p>进而影响消费者的思考和行为的模式，拉近他们之间的距离。</p>
            <p>我们也是一家技术驱动型的互动公司，始终坚信未来的创意是由技术驱动的，</p>
            <p>因此团队致力于将技术与内容做完美的结合，</p>
            <p>并通过覆盖顾客全生命周期的营销解决方案，</p>
            <p>定制化营销内容，让品牌构建属于商户自己的流量池。</p>
            <div class="hr">1111</div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.content-main {
    width: 1200px;
    height: 633px;
    background: url("../assets/about.png") no-repeat center center;
    background-size: 100% auto;
    position: relative;
}
.content-main .desc {
    width: 480px;
    position: absolute;
    right: 20px;
    bottom: 150px;
    height: 450px;
    padding-right: 10px;
}
h1 {
    font-size: 60px;
    color: #fff;
    text-align: right;
}
h2 {
    font-size: 20px;
    color: #fff;
    text-align: right;
    margin: 10px 0 20px 0;
}

.desc p {
    color: #fff;
    font-size: 14px;
    text-align: right;
    line-height: 30px;
}
.hr {
    margin-top: 20px;
    width: 100%;
    height: 1px;
    background-color: #fff;
}
</style>